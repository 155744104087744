import { FC } from 'react';
import Heading from 'raydiant-elements/core/Heading';
import Column from 'raydiant-elements/layout/Column';
import ApplicationInputID from './ApplicationInputID';
import ApplicationInputHelperText from './ApplicationInputHelperText';
import ApplicationInputHelperLink from './ApplicationInputHelperLink';
import ApplicationInputDefaultColor from './ApplicationInputDefaultColor';

interface ApplicationInputTypeColorProps {}

const ApplicationInputTypeColor: FC<ApplicationInputTypeColorProps> = () => {
  return (
    <Column doubleMargin>
      <Column>
        <Heading overline size={5}>
          Properties
        </Heading>
        <ApplicationInputID />
        <ApplicationInputDefaultColor />
      </Column>

      <Column>
        <Heading overline size={5}>
          Appearance
        </Heading>
        <ApplicationInputHelperText />
        <ApplicationInputHelperLink />
      </Column>
    </Column>
  );
};

export default ApplicationInputTypeColor;
