import { FC, useState, useEffect, useRef } from 'react';
import Heading from 'raydiant-elements/core/Heading';
import InputHelperText from 'raydiant-elements/core/InputHelperText';
import Text from 'raydiant-elements/core/Text';
import Scrollable from 'raydiant-elements/layout/Scrollable';
import {
  getPropertyName,
  getPropertyType,
  setPropertyName,
} from '../../utilities/properties';
import { useApplicationFormContext } from '../../hooks/useApplicationForm';
import ApplicationInputTypeText from './ApplicationInputTypeText';
import ApplicationInputTypeSelect from './ApplicationInputTypeSelect';
import ApplicationInputTypeNumber from './ApplicationInputTypeNumber';
import ApplicationInputTypeToggle from './ApplicationInputTypeToggle';
import ApplicationInputTypeButtonGroup from './ApplicationInputTypeButtonGroup';
import PaperModal from '../PaperModal';
import ApplicationInputTypeOAuth from './ApplicationInputTypeOAuth';
import ApplicationInputTypeColor from './ApplicationInputTypeColor';
import ApplicationInputTypeFileUpload from './ApplicationInputTypeFileUpload';
import ApplicationInputTypeProductCatalog from './ApplicationInputTypeProductCatalog';

interface ApplicationInputModalProps {}

const ApplicationInputModal: FC<ApplicationInputModalProps> = () => {
  const {
    isEditable,
    version,
    selectedProperty,
    selectedPropertyIndex,
    setSelectedProperty,
    updateSelectedProperty,
    getInputNameError,
  } = useApplicationFormContext();

  const isOpen = !!selectedProperty;

  // State

  const [inputName, setInputName] = useState(
    selectedProperty ? getPropertyName(selectedProperty, version?.strings) : '',
  );

  // Refs

  const nameRef = useRef<HTMLInputElement | null>(null);
  const prevIsOpenRef = useRef<boolean | null>();

  // Callbacks

  const handleInputNameBlur = () => {
    if (!selectedProperty) return;
    if (!version) return;
    // Update the form value on blur instead of on change.
    updateSelectedProperty(
      ...setPropertyName(selectedProperty, version.strings, inputName),
    );
  };

  // Effects

  // Set inputName and inputId when property changes.
  useEffect(() => {
    setInputName(
      selectedProperty
        ? getPropertyName(selectedProperty, version?.strings)
        : '',
    );
  }, [selectedProperty, version?.strings]);

  // Auto-focus name if empty and modal is opened.
  useEffect(() => {
    if (
      !prevIsOpenRef.current &&
      isOpen &&
      selectedProperty &&
      nameRef.current &&
      !getPropertyName(selectedProperty, version?.strings)
    ) {
      nameRef.current.focus();
    }
    prevIsOpenRef.current = isOpen;
  }, [isOpen, inputName, selectedProperty, version?.strings]);

  // Render

  const nameError =
    selectedPropertyIndex !== null
      ? getInputNameError(selectedPropertyIndex)
      : null;

  return (
    <PaperModal open={isOpen} onClose={() => setSelectedProperty(null)}>
      <PaperModal.Body>
        {selectedProperty && (
          <div>
            <Heading>
              <Text
                ref={nameRef}
                editable={isEditable}
                value={inputName}
                onChange={setInputName}
                onBlur={handleInputNameBlur}
                error={!!nameError}
              />
            </Heading>
            {nameError === 'invalid' && (
              <InputHelperText error>
                Oops! Please provide an input name.
              </InputHelperText>
            )}
            <Heading size={5}>{getPropertyType(selectedProperty)}</Heading>
          </div>
        )}
      </PaperModal.Body>
      <Scrollable>
        <PaperModal.Body>
          {selectedProperty !== null && (
            <>
              {getPropertyType(selectedProperty) === 'text' && (
                <ApplicationInputTypeText />
              )}

              {getPropertyType(selectedProperty) === 'select' && (
                <ApplicationInputTypeSelect />
              )}

              {getPropertyType(selectedProperty) === 'multi select' && (
                <ApplicationInputTypeSelect multiple />
              )}

              {getPropertyType(selectedProperty) === 'number' && (
                <ApplicationInputTypeNumber />
              )}

              {getPropertyType(selectedProperty) === 'toggle' && (
                <ApplicationInputTypeToggle />
              )}

              {getPropertyType(selectedProperty) === 'button group' && (
                <ApplicationInputTypeButtonGroup />
              )}

              {getPropertyType(selectedProperty) === 'oauth' && (
                <ApplicationInputTypeOAuth />
              )}

              {getPropertyType(selectedProperty) === 'file' && (
                <ApplicationInputTypeFileUpload />
              )}

              {getPropertyType(selectedProperty) === 'product catalog' && (
                <ApplicationInputTypeProductCatalog />
              )}

              {getPropertyType(selectedProperty) === 'color' && (
                <ApplicationInputTypeColor />
              )}

            </>
          )}
        </PaperModal.Body>
      </Scrollable>
    </PaperModal>
  );
};

export default ApplicationInputModal;
