import { FC } from 'react';
import InputLabel from 'raydiant-elements/core/InputLabel';
import InputHelperText from 'raydiant-elements/core/InputHelperText';
import ColorField from 'raydiant-elements/core/ColorField';
import Text from 'raydiant-elements/core/Text';
import {
  getPropertyDefault,
  setPropertyDefault,
} from '../../utilities/properties';
import { useApplicationFormContext } from '../../hooks/useApplicationForm';

interface ApplicationInputDefaultToggleProps {}

const ApplicationInputDefaultToggle: FC<ApplicationInputDefaultToggleProps> = () => {
  const {
    isEditable,
    version,
    selectedProperty,
    updateSelectedProperty,
  } = useApplicationFormContext();

  // Callbacks

  const handleChange = (defaultValue: string) => {
    if (!selectedProperty) return;
    if (!version) return;

    updateSelectedProperty(
      setPropertyDefault(selectedProperty, defaultValue),
      version.strings,
    );
  };

  // Render

  const defaultValue = selectedProperty ? getPropertyDefault(selectedProperty) : '';

  return (
    <div>
      <InputLabel>Initial Value</InputLabel>

      {isEditable && (
        <>
          <ColorField value={defaultValue} onChange={handleChange} />
          <InputHelperText>The intial value of the input</InputHelperText>
        </>
      )}

      {!isEditable && selectedProperty && (
        <Text>{getPropertyDefault(selectedProperty) || <em>None</em>}</Text>
      )}
    </div>
  );
};

export default ApplicationInputDefaultToggle;
