import { FC } from 'react';
import InputLabel from 'raydiant-elements/core/InputLabel';
import { PresentationPropertyFileType } from '@raydiant/api-client-js';
import {
  setPropertyFileType,
  getPropertyFileType,
} from '../../utilities/properties';
import { useApplicationFormContext } from '../../hooks/useApplicationForm';
import MultiSelectField from 'raydiant-elements/core/MultiSelectField';
import Row from 'raydiant-elements/layout/Row';

interface ApplicationInputFileTypeProps { }

const availableFileTypes = [
  { value: 'image', label: 'Image' },
  { value: 'video', label: 'Video' },
  { value: 'spreadsheet', label: 'Spreadsheet' },
  { value: 'pdf', label: 'PDF' },
];

const fileTypes = availableFileTypes.map(item => item.value)

const ApplicationInputFileType: FC<ApplicationInputFileTypeProps> = () => {
  const {
    isEditable,
    version,
    selectedProperty,
    updateSelectedProperty,
  } = useApplicationFormContext();

  // Callbacks

  const handleFileTypeChange = (fileType: string[]) => {
    if (!selectedProperty) return;
    if (!version) return;

    updateSelectedProperty(
      setPropertyFileType(
        selectedProperty,
        fileType as PresentationPropertyFileType[],
      ),
      version.strings,
    );
  };

  // Render
  const initial = !!selectedProperty
    ? getPropertyFileType(selectedProperty)
    : [];

  const fileType = Array.isArray(initial)
    ? initial
    : initial
      ? [initial]
      : [];

  return (
    <div>
      <InputLabel>File Type</InputLabel>

      {isEditable && <MultiSelectField
        disabled={!isEditable}
        value={fileType.filter(item => fileTypes.includes(item))}
        onChange={(value) => handleFileTypeChange(value)}
      >
        {availableFileTypes.map((scope) => (
          <MultiSelectField.Option
            key={scope.value}
            value={scope.value}
            label={scope.label}
            disabled={!isEditable}
          />
        ))}
      </MultiSelectField>}

      {!isEditable && (
        <div>
          <Row halfMargin center>
            {fileType ? fileType.filter(item => fileTypes.includes(item)).join(', ') : 'None'}
          </Row>
        </div>
      )}
    </div>
  );
};

export default ApplicationInputFileType;
